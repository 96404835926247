<template>


  <a-row class="auth-wrapper">


    <a-col :span="12" class="auth-sub-wrap">

      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="auth-text">
          <p>Giriş Yap</p>
          <p>Giriş yapamadığınız takdirde yöneticinize başvurunuz.</p>
        </div>
        <a-form-item label="" v-bind="validateInfos.username">
          <a-input v-model:value="modelRef.username" placeholder="Mail Adresi"/>
        </a-form-item>
        <a-form-item label="" v-bind="validateInfos.password">
          <a-input v-model:value="modelRef.password" type="password" placeholder="Password"/>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 14 }">
          <a-button type="primary" @click="onSubmit">
            Giriş
          </a-button>


        </a-form-item>
      </a-form>
    </a-col>

  </a-row>

</template>
<script>

import {reactive, getCurrentInstance,inject } from 'vue'
import {Form} from 'ant-design-vue'
import {notification} from 'ant-design-vue'
import {useStore} from 'vuex'
import router from '../router/router'


export default {
  name: 'auth',

  setup() {

    const store = useStore()
    const axios = inject('axios')
    const useForm = Form.useForm


    const modelRef = reactive({
      username: '',
      password: ''
    })

    const {resetFields, validate, validateInfos} = useForm(
        modelRef,
        reactive({
          username: [
            {
              required: true,
              message: 'Kullanıcı adınızı girin'
            }
          ],
          password: [
            {
              required: true,
              message: 'Şifrenizi Giriniz.'
            }
          ]

        })
    )


    const onSubmit = e => {

      e.preventDefault()

      validate()
          .then(form => {

            axios.post('/auth/login', form)
                .then((res) => {

                  notification.success({
                    message: 'Başarıyla giriş yapıldı.',
                    duration: 3
                  })

                  axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.token

                  store.dispatch('login', res.data).then(() => {
                    return router.replace('/users')

                  })


                }).catch(err => {

              notification.error({
                message: err?.response?.data?.error?.message || 'Hata'
              })

              throw err

            })


          }).catch(err => {

        console.log(err)


        // this.errorStatus = true


      })


    }
    const reset = () => {
      resetFields()
    }
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      validateInfos,
      reset,
      modelRef,
      onSubmit
    }
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>

.locale-changer {
  position: absolute;
  top: 55px;
  right: 55px;
  width: 100px;
}

.locale-changer > select {
  width: 100%;
  border: 0;
  background: #fff;
  border-radius: 4px;
  height: 30px;
  text-indent: 0px;
  padding: 0 10px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.auth-wrapper {

  height: 100vh;
  display: flex;
  align-items: center;
  background: #fff;

  img {
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    width: 100%;
  }
}

form {
  width: 100%;

  button {
    font: 400 20px/40px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    height: 45px;
    width: 100%;
    border-radius: 0;
    margin-top: 10px;
  }
}

.auth-sub-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;

  .ant-form-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;
  }

  input {
    border: 1px solid #0000000D;
    background: #FDFDFD;
    font: 500 18px/35px Poppins;
    letter-spacing: 0px;
    color: #00000061;
    border-radius: 0;
  }
}

.auth-text {
  color: #000000;
  font: 800 20px/30px Poppins;


  p:first-child {
    font-weight: 800;
    margin-bottom: 5px;
  }

  p:last-child {
    font-weight: 600;
    font-size: 16px;
  }
}


</style>
